
require.register("array.prototype.flat/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "array.prototype.flat");
  (function() {
    'use strict';

var define = require('define-properties');
var bind = require('function-bind');

var implementation = require('./implementation');
var getPolyfill = require('./polyfill');
var polyfill = getPolyfill();
var shim = require('./shim');

var boundFlat = bind.call(Function.call, polyfill);

define(boundFlat, {
	getPolyfill: getPolyfill,
	implementation: implementation,
	shim: shim
});

module.exports = boundFlat;
  })();
});