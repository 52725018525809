
require.register("react-with-styles-interface-css/dist/utils/getClassName.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-with-styles-interface-css");
  (function() {
    Object.defineProperty(exports, "__esModule", {
  value: true
});
exports['default'] = getClassName;
/**
 * Construct a class name.
 *
 * namespace {String} Used to construct unique class names.
 * styleName {String} Name identifying the specific style.
 *
 * Return the class name.
 */
function getClassName(namespace, styleName) {
  var namespaceSegment = namespace.length > 0 ? String(namespace) + '__' : '';
  return '' + namespaceSegment + String(styleName);
}
  })();
});