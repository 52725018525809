
require.register("@material-ui/core/styles/mergeClasses.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "@material-ui/core");
  (function() {
    "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _warning = _interopRequireDefault(require("warning"));

var _getDisplayName = _interopRequireDefault(require("recompose/getDisplayName"));

function mergeClasses() {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var baseClasses = options.baseClasses,
      newClasses = options.newClasses,
      Component = options.Component,
      _options$noBase = options.noBase,
      noBase = _options$noBase === void 0 ? false : _options$noBase;

  if (!newClasses) {
    return baseClasses;
  }

  return (0, _extends2.default)({}, baseClasses, Object.keys(newClasses).reduce(function (accumulator, key) {
    'production' !== "production" ? (0, _warning.default)(baseClasses[key] || noBase, ["Material-UI: the key `".concat(key, "` ") + "provided to the classes property is not implemented in ".concat((0, _getDisplayName.default)(Component), "."), "You can only override one of the following: ".concat(Object.keys(baseClasses).join(','))].join('\n')) : void 0;
    'production' !== "production" ? (0, _warning.default)(!newClasses[key] || typeof newClasses[key] === 'string', ["Material-UI: the key `".concat(key, "` ") + "provided to the classes property is not valid for ".concat((0, _getDisplayName.default)(Component), "."), "You need to provide a non empty string instead of: ".concat(newClasses[key], ".")].join('\n')) : void 0;

    if (newClasses[key]) {
      accumulator[key] = "".concat(baseClasses[key], " ").concat(newClasses[key]);
    }

    return accumulator;
  }, {}));
}

var _default = mergeClasses;
exports.default = _default;
  })();
});