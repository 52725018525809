
require.register("react-dates/lib/utils/getActiveElement.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-dates");
  (function() {
    'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports['default'] = getActiveElement;
function getActiveElement() {
  return typeof document !== 'undefined' && document.activeElement;
}
  })();
});