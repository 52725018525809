
require.register("array.prototype.flat/shim.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "array.prototype.flat");
  (function() {
    'use strict';

var define = require('define-properties');
var getPolyfill = require('./polyfill');

module.exports = function shimFlat() {
	var polyfill = getPolyfill();
	define(
		Array.prototype,
		{ flat: polyfill },
		{ flat: function () { return Array.prototype.flat !== polyfill; } }
	);
	return polyfill;
};
  })();
});