
require.register("react-with-direction/dist/constants.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-with-direction");
  (function() {
    Object.defineProperty(exports, "__esModule", {
  value: true
});
var CHANNEL = exports.CHANNEL = '__direction__';

var DIRECTIONS = exports.DIRECTIONS = {
  LTR: 'ltr',
  RTL: 'rtl'
};
  })();
});