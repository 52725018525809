
require.register("function.prototype.name/polyfill.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "function.prototype.name");
  (function() {
    'use strict';

var implementation = require('./implementation');

module.exports = function getPolyfill() {
	return implementation;
};
  })();
});