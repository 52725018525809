
require.register("jss/lib/utils/linkRule.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "jss");
  (function() {
    "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = linkRule;
/**
 * Link rule with CSSStyleRule and nested rules with corresponding nested cssRules if both exists.
 */
function linkRule(rule, cssRule) {
  rule.renderable = cssRule;
  if (rule.rules && cssRule.cssRules) rule.rules.link(cssRule.cssRules);
}
  })();
});