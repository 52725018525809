
require.register("core-js/fn/weak-set.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "core-js");
  (function() {
    require('../modules/es6.object.to-string');
require('../modules/web.dom.iterable');
require('../modules/es6.weak-set');
require('../modules/es7.weak-set.of');
require('../modules/es7.weak-set.from');
module.exports = require('../modules/_core').WeakSet;
  })();
});