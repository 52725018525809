
require.register("react-portal/lib/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-portal");
  (function() {
    'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PortalWithState = exports.Portal = undefined;

var _PortalCompat = require('./PortalCompat');

var _PortalCompat2 = _interopRequireDefault(_PortalCompat);

var _PortalWithState = require('./PortalWithState');

var _PortalWithState2 = _interopRequireDefault(_PortalWithState);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.Portal = _PortalCompat2.default;
exports.PortalWithState = _PortalWithState2.default;
  })();
});