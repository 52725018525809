
require.register("react-dom/test-utils.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-dom");
  (function() {
    'use strict';

if ('production' === 'production') {
  module.exports = require('./cjs/react-dom-test-utils.production.min.js');
} else {
  module.exports = require('./cjs/react-dom-test-utils.development.js');
}
  })();
});