
require.register("react-with-styles-interface-css/dist/utils/constants.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-with-styles-interface-css");
  (function() {
    Object.defineProperty(exports, "__esModule", {
  value: true
});
var GLOBAL_CACHE_KEY = 'reactWithStylesInterfaceCSS';
var MAX_SPECIFICITY = 20;

exports.GLOBAL_CACHE_KEY = GLOBAL_CACHE_KEY;
exports.MAX_SPECIFICITY = MAX_SPECIFICITY;
  })();
});