
require.register("hyphenate-style-name/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "hyphenate-style-name");
  (function() {
    'use strict';

var uppercasePattern = /[A-Z]/g;
var msPattern = /^ms-/;
var cache = {};

function hyphenateStyleName(string) {
    return string in cache
    ? cache[string]
    : cache[string] = string
      .replace(uppercasePattern, '-$&')
      .toLowerCase()
      .replace(msPattern, '-ms-');
}

module.exports = hyphenateStyleName;
  })();
});