
require.register("@babel/runtime/helpers/possibleConstructorReturn.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "@babel/runtime");
  (function() {
    var _typeof = require("../helpers/typeof");

var assertThisInitialized = require("./assertThisInitialized");

function _possibleConstructorReturn(self, call) {
  if (call && (_typeof(call) === "object" || typeof call === "function")) {
    return call;
  }

  return assertThisInitialized(self);
}

module.exports = _possibleConstructorReturn;
  })();
});