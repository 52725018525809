
require.register("react-select/lib/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-select");
  (function() {
    'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
exports.Value = exports.Creatable = exports.AsyncCreatable = exports.Async = undefined;

var _Select = require('./Select');

var _Select2 = _interopRequireDefault(_Select);

var _Async = require('./Async');

var _Async2 = _interopRequireDefault(_Async);

var _AsyncCreatable = require('./AsyncCreatable');

var _AsyncCreatable2 = _interopRequireDefault(_AsyncCreatable);

var _Creatable = require('./Creatable');

var _Creatable2 = _interopRequireDefault(_Creatable);

var _Value = require('./Value');

var _Value2 = _interopRequireDefault(_Value);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_Select2.default.Async = _Async2.default;
_Select2.default.AsyncCreatable = _AsyncCreatable2.default;
_Select2.default.Creatable = _Creatable2.default;
_Select2.default.Value = _Value2.default;

exports.default = _Select2.default;
exports.Async = _Async2.default;
exports.AsyncCreatable = _AsyncCreatable2.default;
exports.Creatable = _Creatable2.default;
exports.Value = _Value2.default;
  })();
});