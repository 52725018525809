
require.register("@material-ui/core/internal/svg-icons/ArrowDropDown.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "@material-ui/core");
  (function() {
    "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _pure = _interopRequireDefault(require("recompose/pure"));

var _SvgIcon = _interopRequireDefault(require("../../SvgIcon"));

var _ref = _react.default.createElement("path", {
  d: "M7 10l5 5 5-5z"
});

/**
 * @ignore - internal component.
 */
var ArrowDropDown = function ArrowDropDown(props) {
  return _react.default.createElement(_SvgIcon.default, props, _ref);
};

ArrowDropDown = (0, _pure.default)(ArrowDropDown);
ArrowDropDown.muiName = 'SvgIcon';
var _default = ArrowDropDown;
exports.default = _default;
  })();
});