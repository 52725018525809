
require.register("dom-helpers/util/hyphenate.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "dom-helpers");
  (function() {
    'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = hyphenate;

var rUpper = /([A-Z])/g;

function hyphenate(string) {
  return string.replace(rUpper, '-$1').toLowerCase();
}
module.exports = exports['default'];
  })();
});