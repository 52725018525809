
require.register("function-bind/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "function-bind");
  (function() {
    'use strict';

var implementation = require('./implementation');

module.exports = Function.prototype.bind || implementation;
  })();
});