
require.register("react-router/lib/PromiseUtils.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {"transform":["loose-envify"]}, "react-router");
  (function() {
    'use strict';

exports.__esModule = true;
exports.isPromise = isPromise;
function isPromise(obj) {
  return obj && typeof obj.then === 'function';
}
  })();
});