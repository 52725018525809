
require.register("react-dates/lib/initialize.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-dates");
  (function() {
    'use strict';

var _registerCSSInterfaceWithDefaultTheme = require('./utils/registerCSSInterfaceWithDefaultTheme');

var _registerCSSInterfaceWithDefaultTheme2 = _interopRequireDefault(_registerCSSInterfaceWithDefaultTheme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

(0, _registerCSSInterfaceWithDefaultTheme2['default'])();
  })();
});