
require.register("object.values/polyfill.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "object.values");
  (function() {
    'use strict';

var implementation = require('./implementation');

module.exports = function getPolyfill() {
	return typeof Object.values === 'function' ? Object.values : implementation;
};
  })();
});