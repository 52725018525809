
require.register("array.prototype.find/shim.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "array.prototype.find");
  (function() {
    'use strict';

var define = require('define-properties');
var getPolyfill = require('./polyfill');

module.exports = function shimArrayPrototypeFind() {
	var polyfill = getPolyfill();

	define(Array.prototype, { find: polyfill }, {
		find: function () {
			return Array.prototype.find !== polyfill;
		}
	});

	return polyfill;
};
  })();
});