
require.register("recompose/setDisplayName.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "recompose");
  (function() {
    "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _setStatic = _interopRequireDefault(require("./setStatic"));

var setDisplayName = function setDisplayName(displayName) {
  return (0, _setStatic.default)('displayName', displayName);
};

var _default = setDisplayName;
exports.default = _default;
  })();
});