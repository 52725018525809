
require.register("@material-ui/core/styles/shape.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "@material-ui/core");
  (function() {
    "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var shape = {
  borderRadius: 4
};
var _default = shape;
exports.default = _default;
  })();
});