
require.register("array.prototype.flat/polyfill.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "array.prototype.flat");
  (function() {
    'use strict';

var implementation = require('./implementation');

module.exports = function getPolyfill() {
	return Array.prototype.flat || implementation;
};
  })();
});