
require.register("consolidated-events/lib/canUseDOM.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "consolidated-events");
  (function() {
    Object.defineProperty(exports, "__esModule", {
  value: true
});
var CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

exports['default'] = CAN_USE_DOM;
  })();
});