
require.register("recompose/getDisplayName.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "recompose");
  (function() {
    "use strict";

exports.__esModule = true;
exports.default = void 0;

var getDisplayName = function getDisplayName(Component) {
  if (typeof Component === 'string') {
    return Component;
  }

  if (!Component) {
    return undefined;
  }

  return Component.displayName || Component.name || 'Component';
};

var _default = getDisplayName;
exports.default = _default;
  })();
});