
require.register("@babel/runtime/helpers/defineProperty.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "@babel/runtime");
  (function() {
    function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

module.exports = _defineProperty;
  })();
});