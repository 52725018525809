
require.register("recompose/wrapDisplayName.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "recompose");
  (function() {
    "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _getDisplayName = _interopRequireDefault(require("./getDisplayName"));

var wrapDisplayName = function wrapDisplayName(BaseComponent, hocName) {
  return hocName + "(" + (0, _getDisplayName.default)(BaseComponent) + ")";
};

var _default = wrapDisplayName;
exports.default = _default;
  })();
});