
require.register("object.values/shim.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "object.values");
  (function() {
    'use strict';

var getPolyfill = require('./polyfill');
var define = require('define-properties');

module.exports = function shimValues() {
	var polyfill = getPolyfill();
	define(Object, { values: polyfill }, {
		values: function testValues() {
			return Object.values !== polyfill;
		}
	});
	return polyfill;
};
  })();
});