
require.register("dom-helpers/style/removeStyle.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "dom-helpers");
  (function() {
    'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = removeStyle;
function removeStyle(node, key) {
  return 'removeProperty' in node.style ? node.style.removeProperty(key) : node.style.removeAttribute(key);
}
module.exports = exports['default'];
  })();
});