
require.register("@material-ui/core/Fade/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "@material-ui/core");
  (function() {
    "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _Fade.default;
  }
});

var _Fade = _interopRequireDefault(require("./Fade"));
  })();
});