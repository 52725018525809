
require.register("react-dates/lib/utils/getCalendarMonthWidth.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-dates");
  (function() {
    "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getCalendarMonthWidth;
var CALENDAR_MONTH_PADDING = 9;

function getCalendarMonthWidth(daySize) {
  return 7 * (daySize + 1) + 2 * (CALENDAR_MONTH_PADDING + 1);
}
  })();
});