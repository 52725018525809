
require.register("dom-helpers/query/isWindow.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "dom-helpers");
  (function() {
    "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getWindow;
function getWindow(node) {
  return node === node.window ? node : node.nodeType === 9 ? node.defaultView || node.parentWindow : false;
}
module.exports = exports["default"];
  })();
});