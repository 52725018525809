
require.register("has/src/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "has");
  (function() {
    'use strict';

var bind = require('function-bind');

module.exports = bind.call(Function.call, Object.prototype.hasOwnProperty);
  })();
});