
require.register("d3-time/build/d3-time.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "d3-time");
  (function() {
    (function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
  typeof define === 'function' && define.amd ? define(['exports'], factory) :
  (factory((global.d3_time = global.d3_time || {})));
}(this, function (exports) { 'use strict';

  var version = "0.2.6";

  var t0 = new Date;
  var t1 = new Date;
  function newInterval(floori, offseti, count, field) {

    function interval(date) {
      return floori(date = new Date(+date)), date;
    }

    interval.floor = interval;

    interval.ceil = function(date) {
      return floori(date = new Date(date - 1)), offseti(date, 1), floori(date), date;
    };

    interval.round = function(date) {
      var d0 = interval(date),
          d1 = interval.ceil(date);
      return date - d0 < d1 - date ? d0 : d1;
    };

    interval.offset = function(date, step) {
      return offseti(date = new Date(+date), step == null ? 1 : Math.floor(step)), date;
    };

    interval.range = function(start, stop, step) {
      var range = [];
      start = interval.ceil(start);
      step = step == null ? 1 : Math.floor(step);
      if (!(start < stop) || !(step > 0)) return range; // also handles Invalid Date
      do range.push(new Date(+start)); while (offseti(start, step), floori(start), start < stop)
      return range;
    };

    interval.filter = function(test) {
      return newInterval(function(date) {
        while (floori(date), !test(date)) date.setTime(date - 1);
      }, function(date, step) {
        while (--step >= 0) while (offseti(date, 1), !test(date));
      });
    };

    if (count) {
      interval.count = function(start, end) {
        t0.setTime(+start), t1.setTime(+end);
        floori(t0), floori(t1);
        return Math.floor(count(t0, t1));
      };

      interval.every = function(step) {
        step = Math.floor(step);
        return !isFinite(step) || !(step > 0) ? null
            : !(step > 1) ? interval
            : interval.filter(field
                ? function(d) { return field(d) % step === 0; }
                : function(d) { return interval.count(0, d) % step === 0; });
      };
    }

    return interval;
  }

  var millisecond = newInterval(function() {
    // noop
  }, function(date, step) {
    date.setTime(+date + step);
  }, function(start, end) {
    return end - start;
  });

  // An optimized implementation for this simple case.
  millisecond.every = function(k) {
    k = Math.floor(k);
    if (!isFinite(k) || !(k > 0)) return null;
    if (!(k > 1)) return millisecond;
    return newInterval(function(date) {
      date.setTime(Math.floor(date / k) * k);
    }, function(date, step) {
      date.setTime(+date + step * k);
    }, function(start, end) {
      return (end - start) / k;
    });
  };

  var second$1 = 1e3;
  var minute = 6e4;
  var hour = 36e5;
  var day = 864e5;
  var week = 6048e5;

  var second = newInterval(function(date) {
    date.setTime(Math.floor(date / second$1) * second$1);
  }, function(date, step) {
    date.setTime(+date + step * second$1);
  }, function(start, end) {
    return (end - start) / second$1;
  }, function(date) {
    return date.getUTCSeconds();
  });

  var minute$1 = newInterval(function(date) {
    date.setTime(Math.floor(date / minute) * minute);
  }, function(date, step) {
    date.setTime(+date + step * minute);
  }, function(start, end) {
    return (end - start) / minute;
  }, function(date) {
    return date.getMinutes();
  });

  var hour$1 = newInterval(function(date) {
    var offset = date.getTimezoneOffset() * minute % hour;
    if (offset < 0) offset += hour;
    date.setTime(Math.floor((+date - offset) / hour) * hour + offset);
  }, function(date, step) {
    date.setTime(+date + step * hour);
  }, function(start, end) {
    return (end - start) / hour;
  }, function(date) {
    return date.getHours();
  });

  var day$1 = newInterval(function(date) {
    date.setHours(0, 0, 0, 0);
  }, function(date, step) {
    date.setDate(date.getDate() + step);
  }, function(start, end) {
    return (end - start - (end.getTimezoneOffset() - start.getTimezoneOffset()) * minute) / day;
  }, function(date) {
    return date.getDate() - 1;
  });

  function weekday(i) {
    return newInterval(function(date) {
      date.setHours(0, 0, 0, 0);
      date.setDate(date.getDate() - (date.getDay() + 7 - i) % 7);
    }, function(date, step) {
      date.setDate(date.getDate() + step * 7);
    }, function(start, end) {
      return (end - start - (end.getTimezoneOffset() - start.getTimezoneOffset()) * minute) / week;
    });
  }

  var sunday = weekday(0);
  var monday = weekday(1);
  var tuesday = weekday(2);
  var wednesday = weekday(3);
  var thursday = weekday(4);
  var friday = weekday(5);
  var saturday = weekday(6);

  var month = newInterval(function(date) {
    date.setHours(0, 0, 0, 0);
    date.setDate(1);
  }, function(date, step) {
    date.setMonth(date.getMonth() + step);
  }, function(start, end) {
    return end.getMonth() - start.getMonth() + (end.getFullYear() - start.getFullYear()) * 12;
  }, function(date) {
    return date.getMonth();
  });

  var year = newInterval(function(date) {
    date.setHours(0, 0, 0, 0);
    date.setMonth(0, 1);
  }, function(date, step) {
    date.setFullYear(date.getFullYear() + step);
  }, function(start, end) {
    return end.getFullYear() - start.getFullYear();
  }, function(date) {
    return date.getFullYear();
  });

  var utcMinute = newInterval(function(date) {
    date.setUTCSeconds(0, 0);
  }, function(date, step) {
    date.setTime(+date + step * minute);
  }, function(start, end) {
    return (end - start) / minute;
  }, function(date) {
    return date.getUTCMinutes();
  });

  var utcHour = newInterval(function(date) {
    date.setUTCMinutes(0, 0, 0);
  }, function(date, step) {
    date.setTime(+date + step * hour);
  }, function(start, end) {
    return (end - start) / hour;
  }, function(date) {
    return date.getUTCHours();
  });

  var utcDay = newInterval(function(date) {
    date.setUTCHours(0, 0, 0, 0);
  }, function(date, step) {
    date.setUTCDate(date.getUTCDate() + step);
  }, function(start, end) {
    return (end - start) / day;
  }, function(date) {
    return date.getUTCDate() - 1;
  });

  function utcWeekday(i) {
    return newInterval(function(date) {
      date.setUTCHours(0, 0, 0, 0);
      date.setUTCDate(date.getUTCDate() - (date.getUTCDay() + 7 - i) % 7);
    }, function(date, step) {
      date.setUTCDate(date.getUTCDate() + step * 7);
    }, function(start, end) {
      return (end - start) / week;
    });
  }

  var utcSunday = utcWeekday(0);
  var utcMonday = utcWeekday(1);
  var utcTuesday = utcWeekday(2);
  var utcWednesday = utcWeekday(3);
  var utcThursday = utcWeekday(4);
  var utcFriday = utcWeekday(5);
  var utcSaturday = utcWeekday(6);

  var utcMonth = newInterval(function(date) {
    date.setUTCHours(0, 0, 0, 0);
    date.setUTCDate(1);
  }, function(date, step) {
    date.setUTCMonth(date.getUTCMonth() + step);
  }, function(start, end) {
    return end.getUTCMonth() - start.getUTCMonth() + (end.getUTCFullYear() - start.getUTCFullYear()) * 12;
  }, function(date) {
    return date.getUTCMonth();
  });

  var utcYear = newInterval(function(date) {
    date.setUTCHours(0, 0, 0, 0);
    date.setUTCMonth(0, 1);
  }, function(date, step) {
    date.setUTCFullYear(date.getUTCFullYear() + step);
  }, function(start, end) {
    return end.getUTCFullYear() - start.getUTCFullYear();
  }, function(date) {
    return date.getUTCFullYear();
  });

  var timeMilliseconds = millisecond.range;
  var timeSeconds = second.range;
  var timeMinutes = minute$1.range;
  var timeHours = hour$1.range;
  var timeDays = day$1.range;
  var timeSundays = sunday.range;
  var timeMondays = monday.range;
  var timeTuesdays = tuesday.range;
  var timeWednesdays = wednesday.range;
  var timeThursdays = thursday.range;
  var timeFridays = friday.range;
  var timeSaturdays = saturday.range;
  var timeWeeks = sunday.range;
  var timeMonths = month.range;
  var timeYears = year.range;

  var utcMilliseconds = timeMilliseconds;
  var utcSeconds = timeSeconds;
  var utcMinutes = utcMinute.range;
  var utcHours = utcHour.range;
  var utcDays = utcDay.range;
  var utcSundays = utcSunday.range;
  var utcMondays = utcMonday.range;
  var utcTuesdays = utcTuesday.range;
  var utcWednesdays = utcWednesday.range;
  var utcThursdays = utcThursday.range;
  var utcFridays = utcFriday.range;
  var utcSaturdays = utcSaturday.range;
  var utcWeeks = utcSunday.range;
  var utcMonths = utcMonth.range;
  var utcYears = utcYear.range;

  exports.timeMilliseconds = timeMilliseconds;
  exports.timeSeconds = timeSeconds;
  exports.timeMinutes = timeMinutes;
  exports.timeHours = timeHours;
  exports.timeDays = timeDays;
  exports.timeSundays = timeSundays;
  exports.timeMondays = timeMondays;
  exports.timeTuesdays = timeTuesdays;
  exports.timeWednesdays = timeWednesdays;
  exports.timeThursdays = timeThursdays;
  exports.timeFridays = timeFridays;
  exports.timeSaturdays = timeSaturdays;
  exports.timeWeeks = timeWeeks;
  exports.timeMonths = timeMonths;
  exports.timeYears = timeYears;
  exports.utcMilliseconds = utcMilliseconds;
  exports.utcSeconds = utcSeconds;
  exports.utcMinutes = utcMinutes;
  exports.utcHours = utcHours;
  exports.utcDays = utcDays;
  exports.utcSundays = utcSundays;
  exports.utcMondays = utcMondays;
  exports.utcTuesdays = utcTuesdays;
  exports.utcWednesdays = utcWednesdays;
  exports.utcThursdays = utcThursdays;
  exports.utcFridays = utcFridays;
  exports.utcSaturdays = utcSaturdays;
  exports.utcWeeks = utcWeeks;
  exports.utcMonths = utcMonths;
  exports.utcYears = utcYears;
  exports.timeMillisecond = millisecond;
  exports.timeSecond = second;
  exports.timeMinute = minute$1;
  exports.timeHour = hour$1;
  exports.timeDay = day$1;
  exports.timeSunday = sunday;
  exports.timeMonday = monday;
  exports.timeTuesday = tuesday;
  exports.timeWednesday = wednesday;
  exports.timeThursday = thursday;
  exports.timeFriday = friday;
  exports.timeSaturday = saturday;
  exports.timeWeek = sunday;
  exports.timeMonth = month;
  exports.timeYear = year;
  exports.utcMillisecond = millisecond;
  exports.utcSecond = second;
  exports.utcMinute = utcMinute;
  exports.utcHour = utcHour;
  exports.utcDay = utcDay;
  exports.utcSunday = utcSunday;
  exports.utcMonday = utcMonday;
  exports.utcTuesday = utcTuesday;
  exports.utcWednesday = utcWednesday;
  exports.utcThursday = utcThursday;
  exports.utcFriday = utcFriday;
  exports.utcSaturday = utcSaturday;
  exports.utcWeek = utcSunday;
  exports.utcMonth = utcMonth;
  exports.utcYear = utcYear;
  exports.version = version;
  exports.timeInterval = newInterval;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
  })();
});