
require.register("strict-uri-encode/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "strict-uri-encode");
  (function() {
    'use strict';
module.exports = function (str) {
	return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
		return '%' + c.charCodeAt(0).toString(16).toUpperCase();
	});
};
  })();
});