
require.register("react-modal/lib/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-modal");
  (function() {
    "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Modal = require("./components/Modal");

var _Modal2 = _interopRequireDefault(_Modal);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = _Modal2.default;
module.exports = exports["default"];
  })();
});