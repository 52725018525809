
require.register("react-with-direction/dist/proptypes/brcast.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-with-direction");
  (function() {
    Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

exports['default'] = _propTypes2['default'].shape({
  getState: _propTypes2['default'].func,
  setState: _propTypes2['default'].func,
  subscribe: _propTypes2['default'].func
});
  })();
});