
require.register("blueimp-load-image/js/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "blueimp-load-image");
  (function() {
    module.exports = require('./load-image')

require('./load-image-scale')
require('./load-image-meta')
require('./load-image-fetch')
require('./load-image-exif')
require('./load-image-exif-map')
require('./load-image-orientation')
  })();
});