
require.register("recompose/shallowEqual.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "recompose");
  (function() {
    "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _shallowEqual = _interopRequireDefault(require("fbjs/lib/shallowEqual"));

var _default = _shallowEqual.default;
exports.default = _default;
  })();
});