
require.register("react-dates/lib/utils/registerCSSInterfaceWithDefaultTheme.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-dates");
  (function() {
    'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports['default'] = registerCSSInterfaceWithDefaultTheme;

var _reactWithStylesInterfaceCss = require('react-with-styles-interface-css');

var _reactWithStylesInterfaceCss2 = _interopRequireDefault(_reactWithStylesInterfaceCss);

var _registerInterfaceWithDefaultTheme = require('./registerInterfaceWithDefaultTheme');

var _registerInterfaceWithDefaultTheme2 = _interopRequireDefault(_registerInterfaceWithDefaultTheme);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

function registerCSSInterfaceWithDefaultTheme() {
  (0, _registerInterfaceWithDefaultTheme2['default'])(_reactWithStylesInterfaceCss2['default']);
}
  })();
});