
require.register("react-moment-proptypes/src/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-moment-proptypes");
  (function() {
    var moment = require('moment');
var momentValidationWrapper = require('./moment-validation-wrapper');
var core = require('./core');

module.exports = {

  momentObj : core.createMomentChecker(
    'object',
    function(obj) {
      return typeof obj === 'object';
    },
    function isValid(value) {
      return momentValidationWrapper.isValidMoment(value);
    },
    'Moment'
  ),

  momentString : core.createMomentChecker(
    'string',
    function(str) {
      return typeof str === 'string';
    },
    function isValid(value) {
      return momentValidationWrapper.isValidMoment(moment(value));
    },
    'Moment'
  ),

  momentDurationObj : core.createMomentChecker(
    'object',
    function(obj) {
      return typeof obj === 'object';
    },
    function isValid(value) {
      return moment.isDuration(value);
    },
    'Duration'
  ),

};
  })();
});