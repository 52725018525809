
require.register("react-dates/lib/utils/getTransformStyles.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-dates");
  (function() {
    "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getTransformStyles;
function getTransformStyles(transformValue) {
  return {
    transform: transformValue,
    msTransform: transformValue,
    MozTransform: transformValue,
    WebkitTransform: transformValue
  };
}
  })();
});