
require.register("function.prototype.name/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "function.prototype.name");
  (function() {
    'use strict';

var define = require('define-properties');
var bind = require('function-bind');

var implementation = require('./implementation');
var getPolyfill = require('./polyfill');
var shim = require('./shim');

var bound = bind.call(Function.call, implementation);

define(bound, {
	getPolyfill: getPolyfill,
	implementation: implementation,
	shim: shim
});

module.exports = bound;
  })();
});