
require.register("react-portal/lib/utils.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "react-portal");
  (function() {
    'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var canUseDOM = exports.canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  })();
});