
require.register("object.entries/shim.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "object.entries");
  (function() {
    'use strict';

var getPolyfill = require('./polyfill');
var define = require('define-properties');

module.exports = function shimEntries() {
	var polyfill = getPolyfill();
	define(Object, { entries: polyfill }, {
		entries: function testEntries() {
			return Object.entries !== polyfill;
		}
	});
	return polyfill;
};
  })();
});