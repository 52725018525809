
require.register("object.values/index.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "object.values");
  (function() {
    'use strict';

var define = require('define-properties');

var implementation = require('./implementation');
var getPolyfill = require('./polyfill');
var shim = require('./shim');

var polyfill = getPolyfill();

define(polyfill, {
	getPolyfill: getPolyfill,
	implementation: implementation,
	shim: shim
});

module.exports = polyfill;
  })();
});